import { PayNowMakePaymentRequest } from "../models/pay-now-make-payment";
import {patientService} from '../../patient/services/patient-service';
import {
  PatientPaymentSchedule
} from '../../patient/components/models/patient-payment.schedule';
import {
  PatientPaymentProgram
} from '../../patient/components/models/patient-payment-program';
import {PatientInfo} from '../components/mobile-payment-view';
import {Payment} from '../../patient/components/models/payment';
import {AxiosResultStatus} from '../../shared/service/axios-result-status';
import { AxiosSavePayload } from "src/shared/service/axios-save-payload";
import { axiosSaveHelper } from "src/shared/service/axios-save-helper";
import { AxiosReadPayload } from "../../shared/service/axios-read-payload";
import { axiosReadHelper } from "../../shared/service/axios-read-helper";
import { PatientEncounter } from "src/patient/components/models/patient-encounter";
import moment from "moment";

class PayNowService {

  async checkEmail(data: { clientId: number | string; email: string, authToken: string }) {
    const serviceUrl = `/patient-encounter/v2/patient/encounter/keys?clientId=${data.clientId}&isConverted=true&linkedUserId=${encodeURIComponent(data.email)}`;
      return await this.postApi("GET", serviceUrl, null, data.authToken );
  }

  async getPaymentChannels(authToken: string) {
    const serviceurl = `/payment/v2/payment/paymentchannels`;
    return await this.postApi("GET", serviceurl, null, authToken);
  }

  async getPatientMasterLedger(clientId: number, patientId: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: -1,
      url: `payment/v2/payment/ledger?clientId=${clientId}&patientId=${patientId}`

    };
    return await axiosReadHelper(payload);
  }

  async updatePaymentDetails(paramId: number, paymentDetails: any): Promise<AxiosResultStatus> {
    const { paymentId } = paymentDetails;
    delete paymentDetails.paymentId;
    const payload: AxiosSavePayload = {
      dataToSave: paymentDetails,
      dataId: paramId,
      isPatch: true,
      url: `payment/v2/payment/${paymentId}`,
    };
    let result: any = await axiosSaveHelper(payload);
    return result;
  }
	
	async getConfig(configId: string, configIdSource: string, configKey: string, authToken: string) {
    const serviceurl =  `/configuration/v2/configuration/customconfig/${configId}/${configIdSource}/${configKey}`;
    return await this.postApi("GET", serviceurl, null, authToken);
	}

  async getAnonymousRequestToken() {
    const jwt = require("jsonwebtoken");
    const token = jwt.sign({}, process.env.REACT_APP_AWS_GUEST_TOKEN_SECRET, {
      audience: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
      subject: process.env.REACT_APP_AWS_USER_POOL_ID,
    });
    const serviceurl =  `/user/v2/guest`;
    return await this.postApi("POST", serviceurl, null, token);
	}

  async getLaunchPayload(payload: string, authToken: string) {
    const serviceurl = `/clients/v2/client/paynow/url-params`;
    return await this.postApi("POST", serviceurl, payload, authToken, 'text/plain');
  }

  async submitPayment(payment: PayNowMakePaymentRequest | undefined, authToken: string) {
    const serviceurl =  `/payment/v2/payment`;
    return await this.postApi("POST", serviceurl, payment, authToken);
  }
	
	postApi(method:string, path:string, postBody?:any, authToken?:string, contentType?: string, cacheMode?: 'default' | 'reload' | 'no-cache') {
    let requestConfig:any = this.getRequestConfig(method, authToken, contentType);
    if (postBody) {
      requestConfig.body = JSON.stringify(postBody)
    }
    if(cacheMode) {
      requestConfig = {
        ...requestConfig,
        cache: cacheMode
      }
    }
		
		return fetch(`${process.env.REACT_APP_UNIFIED_AWS_APPGATEWAY_URI}${path}`, requestConfig)
        .then((response) => {
          if (contentType === 'text/plain') {
            return response.text();
          } else {
            return response.json();
          }
        })
        .then((responseData) => {
          return responseData;
        })
        .catch((error) => console.error(error));
	}


  getRequestConfig(method: string, authToken?: string, contentType?: string):any  {

    if (!contentType) {
      contentType = 'application/json';
    } 

    if ((authToken !== null) && (authToken !== undefined) && (authToken !== '')) {
      return {      
        method: method,
        headers: {
          'Accept': contentType,
          'Content-Type': contentType,
          'Authorization': 'Bearer ' + authToken,
          'x-cog-id': authToken
        },
      }
    } else {
      return {
        method: method,
        headers: {
          'Accept': contentType,
          'Content-Type': contentType
        },
      }
    }
  }

  async getClientFacility(facilityId: string, authToken: string){

    const serviceUrl = `/clients/v2/client/facility/${facilityId}/guest`
    return await this.postApi("GET", serviceUrl, null, authToken);

  }

  async getClientFacilities(clientId: number, authToken: string){
    const serviceUrl = `/clients/v2/client/${clientId}/facility`
    return await this.postApi("GET", serviceUrl, null, authToken);
  }

  async getPatientEncounter(patientEncounterId: number, authToken: string, config?: Record<string, any>) {
    const serviceurl = `/patient-encounter/v2/patient/encounter/${patientEncounterId}`;
    return await this.postApi("GET", serviceurl, null, authToken, undefined, config?.cacheMode);
  }

  async getPatient(patientId: number, authToken: string) {
    const serviceurl = `/patient/v2/patient/${patientId}`;
    return await this.postApi("GET", serviceurl, null, authToken);
  }

  async getEvaluateRule(instanceOfCare: PatientEncounter, authToken: string): Promise<AxiosResultStatus> {
    const serviceurl =
      `/patientrecord/patient/${instanceOfCare.patientId}/encounter/${instanceOfCare.patientEncounterId}/evaluaterule`;
    return await this.postApi("POST", serviceurl, instanceOfCare, authToken);
  }

  mapPaymentInfoToRequestObj(payment: Payment, patientInfo: PatientInfo, isOptimal?: boolean, extraData?: any){
    return {
      areDocsSigned: payment?.paymentChannelId === 4 ? true : false,
      downPmtAmt: +payment.paymentAmt,
      isACH: false,
      isHighRisk: false,
      isPaidInFull: !isOptimal,
      patientPaymentProgramId: 0,
      patientPaymentSchedule: {
        downPmtAmt: Number(payment.paymentAmt.toFixed(2)),
        paymentDueAmt: isOptimal ? Number(extraData?.paymentDueAmt.toFixed(2)) : 0,
        paymentFreq: isOptimal ? "M" : "F",
        pfrAmt: patientInfo.pfrAmt,
        pfrBalance: patientInfo.pfrAmt,
        remainingTerms: isOptimal ? extraData?.remainingTerms : 0,
        scheduleStartDt: isOptimal ? extraData?.scheduleStartDt : new Date().toISOString(),
        scheduleStatus: isOptimal ? "Active" : "Pending",
        terms: isOptimal ? extraData?.remainingTerms : 0,
      } as PatientPaymentSchedule
    } as PatientPaymentProgram
  }

  async submitMobilePayment(payment: Payment, patientInfo: PatientInfo, isOptimal?: Boolean, extraData?: any){

    const paymentProgramObj = this.mapPaymentInfoToRequestObj(payment, patientInfo, !!isOptimal, extraData);
    const resolvedPromises = await Promise.all([
      patientService.updatePatient({
            contact: {
              email: payment.receiptEmail,
            }
          },
          payment.patientId!,
      ),
      patientService.saveIOCWithPartialObject({
        hasChampions: false
      }, payment.patientEncounterId!),
      patientService.savePatientPaymentProgram({
        paymentProgram: paymentProgramObj,
        patientId: payment.patientId!,
        encounterId: payment.patientEncounterId!
      })
    ])

    resolvedPromises.forEach((resolvedPromise: Awaited<AxiosResultStatus>) => {
      if(resolvedPromise.hasErrors){
          return resolvedPromise
      }
    })

    const [, , paymentProgram] = resolvedPromises;

    const paymentResponse = await patientService.createPayment({
      ...payment,
      isRecurringDownPayment: !!isOptimal,
      patientPaymentScheduleId: paymentProgram.entity.patientPaymentSchedule.patientPaymentScheduleId,
      paymentGroupId: paymentProgram.entity.patientPaymentSchedule.patientPaymentScheduleId.toString(),
    })

    if(paymentResponse.hasErrors) return paymentResponse;

    const convertResponse = await patientService.convertPatientEncounter(payment.patientId!, payment.patientEncounterId!);

    if(convertResponse.hasErrors) return convertResponse;

    if (isOptimal) {
      Promise.all([
        patientService.savePatientPaymentProgram({
          paymentProgram: {
            ...paymentProgramObj,
            isDownPmtCaptured: !!isOptimal,
            patientPaymentSchedule: {
              ...paymentProgramObj.patientPaymentSchedule,
              nextPaymentDueDt: isOptimal? moment().add(1, 'months').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
              paymentStartDay: isOptimal ? Math.min(moment().date(), 28).toString() : '',
            },
            patientPaymentProgramId: paymentProgram.entity.patientPaymentProgramId,
          },
          patientId: payment.patientId!,
          encounterId: payment.patientEncounterId!
        }),
        patientService.saveIOCWithPartialObject({
          finClearanceStatus: 'Converted',
          finClearanceStatusDt: moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        }, payment.patientEncounterId!),
        patientService.updateDocumentSignature({
          data: {
            documentStatus: 'Available',
            signatureStatus: 'Signature Completed',
            documentLocation: 'paymentAcknowledgement'
          },
          encounterId: payment.patientEncounterId!
        }),
        patientService.saveStatus({
          // @ts-ignore
          workFlow: {
            workflowId: 3,
            workflowStatus: {
              workflowStatusId: 12,
              workflowStatusDesc: '',
            },
            workflowSubStatus: {
              workflowSubStatusId: 23,
              workflowSubStatusDesc: '',
            },
          },
          encounterId: payment.patientEncounterId!,
          patientId: payment.patientId!,
        })
      ]);
    }

    patientService.integrationUpdate({
      patientEncounterId: payment.patientEncounterId!,
      crmTypeSlug: 'salesforce',
      patientId: payment.patientId!
    });

    //need this property for conditional on the thunk level
    convertResponse.entity.paymentStatus = "Success";

    return convertResponse

  }

  async makePayment(paramId: number, paymentDetails: Payment): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: paymentDetails,
      dataId: paramId,
      url: `payment/v2/payment`,
    };
    let result: any = await axiosSaveHelper(payload);
    return result;
  }

  async eventPayment(payment: any, authToken: string) {
    const serviceurl =  `/salesforce/event/payment`;
    return await this.postApi("POST", serviceurl, payment, authToken);
  }

  async updatePaymentProgram(programId: number, data: any = {}): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: data,
      dataId: -2,
      isPatch: true,
      url: `payment/v2/program/${programId}`,
    };
    return await axiosSaveHelper(payload);
  }

  async createRefund(paymentId: number, patientEncounterId:number,patientPaymentScheduleId:number, paymentAmt:number): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: {patientEncounterId, patientPaymentScheduleId, paymentAmt},
      dataId: 0,
      isPatch: false,
      url: `payment/v2/payment/${paymentId}/refund`,
    };
    return await axiosSaveHelper(payload);
  }


  async captureDownPayment(paymentId: number): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: {},
      dataId: paymentId,
      isPatch: false,
      url: `payment/v2/payment/capture-payment`,
    };
    return await axiosSaveHelper(payload);
  }

  async setNextPaymentDueDt(scheduleId: number, paymentDueDt: string): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: {nextPaymentDueDt: paymentDueDt},
      dataId: -2,
      isPatch: true,
      url: `payment/v2/schedule/${scheduleId}`,
    };
    return await axiosSaveHelper(payload);
  }

}

export const paynowService = new PayNowService();