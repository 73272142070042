import { clientFacilityStatus } from "../implementation-specialist/misc/client-and-facility-status";
import { emptyPatientChampion, miniChampion, PatientChampion } from "../patient/components/models/patient-champion";
import { PatientEncounter } from "../patient/components/models/patient-encounter";
import { PatientViewModel } from "../patient/components/models/patient-view-model";
import { Payment, V2PaymentDetail } from "../patient/components/models/payment";
import { StripePaymentMethod } from "../patient/components/models/stripe-payment-method";
import { formatNumberToUSD } from "./calculators";
import { clipboardKeys, formats, StatusColors } from "./enums";
import { ClientStatusCardViewModel } from "./model/client-status-card";
import { Clipboard} from "./model/clipboard";
import { recentlyViewedPatientsObj } from "./model/recentlyViewed";
import {IdProviderFormat, SamlUrl} from "../security/services/SSO";
import _ from "lodash";
import {configSettings} from "./configuration/config-settings";
import { SignatureStatus } from "../patient/components/models/signature-tracking";
import { showErrorStatus } from "../security/state/user-slice";
import { ExternalAccountItem, ImplementationFacility } from "../implementation-specialist/components/implementation-clients/details/models/implementation-facility";
import moment from "moment";
import { EstLevelOfCare } from "../admissions-advisor/models/estimator";
import { AnyAction } from "@reduxjs/toolkit";
import { FormikProps } from "formik";
import { getStateByName, getStateByAbbrev } from './misc/us-states';


export class Utils {
    static isObjectEmpty(obj: Object): boolean {
        return Object.keys(obj).length === 0;
    }

    // returns a number formatted into a shorter syntax.
    // example: 5000 becomes 5k, 50100 becomes 50.1k, 5120000 becomes 5.12m.
    // Does not work on numbers < 1000. Input only numbers > 1000.
    static abbreviateNum(number: number, decPlaces = 2) {
        // 2 decimal places => 1.00, 3 => 1.000, etc
        let strNum = "";
        decPlaces = Math.pow(10, decPlaces);
        // Enumerate number abbreviations
        const abbrev = ["k", "m", "b", "t"];
        // Go through the array backwards, so we do the largest first
        for (let i = abbrev.length - 1; i >= 0; i--) {
            // Convert array index to "1000", "1000000", etc
            const size = Math.pow(10, (i + 1) * 3);
            // If the number is bigger or equal do the abbreviation
            if (size <= number) {
                // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                // This gives us nice rounding to a particular decimal place.
                number = Math.round((number * decPlaces) / size) / decPlaces;
                // Handle special case where we round up to the next abbreviation
                if (number === 1000 && i < abbrev.length - 1) {
                    number = 1;
                    i++;
                }
                // Add the letter for the abbreviation
                //  let strNum = number.toString() += abbrev[]
                strNum = "" + number + abbrev[i];
                // We are done... stop
                break;
            }
        }
        return strNum;
    }

    // Array must have a key called 'sortOrder'
    static sortBySortOrder(array: any[]): any[] {
        return array.sort((a, b) => {
            if (a.sortOrder < b.sortOrder) {
                return -1;
            } else if (a.sortOrder > b.sortOrder) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    static getDocumentStatusColor(doesDocumentTypeExist: boolean, documentStatus?: string) {
      if (doesDocumentTypeExist) {
        if (
            documentStatus === SignatureStatus.pending || documentStatus === SignatureStatus.PendingSignature || documentStatus === SignatureStatus.created
            || documentStatus === SignatureStatus.DocumentCreated || documentStatus === SignatureStatus.PendingEsign
            || documentStatus === SignatureStatus.Available || documentStatus === SignatureStatus.SignatureRequested
          ) {
          return StatusColors.warning;
        } else if (documentStatus === SignatureStatus.signed || documentStatus === SignatureStatus.SignatureCompleted) {
          return StatusColors.success;
        }
      } else {
        // document status does not exist. return gray
        return StatusColors.gray;
      }
      return StatusColors.gray;
    }

    static sortAlphabetically(data: any[], keyToSort: string) {
        return data.sort((a, b) => {
            if(a[keyToSort].toLowerCase() < b[keyToSort].toLowerCase()) { return -1; }
            if(a[keyToSort].toLowerCase() > b[keyToSort].toLowerCase()) { return 1; }
            return 0;
        })
    }

    static sortByNumber(data: any[], keyToSort: string) {
        return data.sort((a, b) => {
            if(a[keyToSort] < b[keyToSort]) { return -1; }
            if(a[keyToSort] > b[keyToSort]) { return 1; }
            return 0;
        })
    }

    static convertBoolToYesNo = (value: boolean) => (
        value ? 'Yes' : 'No'
    );

    static convertDate = (date?: Date, showNoDateText?: boolean) => { // converts to 'YYYY-MM-DD format'
      if (!!date && date instanceof Date && !isNaN(date?.getTime())) {
          const convertedDate = new Date(date).toISOString().split("T")[0];
          return convertedDate;
      }
      else {
          return showNoDateText ? 'No Date' : "";
      }
  };

    static convertISODate = (date: Date, details?: boolean) => {
        let year = date.getFullYear();
        let month = date.getMonth() +1;
        let day = date.getDate()
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        let stringMinutes:string = '';
        let strTime = ""

        hours %= 12;
        hours = hours || 12;
        stringMinutes = minutes < 10 ? `0${minutes.toString()}` : minutes.toString();

        if(details) {
            strTime = `${month}/${day}/${year} ${hours}:${stringMinutes} ${ampm}`;
        } else {
            strTime = `${month}/${day}/${year}`;
        }

        return strTime;
    }

    static parseDateTimeString = (dateTimeString: string): Date => {

        const [dateString, timeString] = dateTimeString.split(" ");
        const [month, day, year] = dateString.split("/").map(str => parseInt(str))

        let [hours, minutes, seconds] = timeString.split(":").map(str => parseInt(str))

        const [ampm] = timeString.split(" ")

        if (ampm === "PM" && hours !== 12) {
            hours += 12
        } else if (ampm === "AM" && hours === 12) {
            hours = 0
        }

        const date = new Date(year, month - 1, day, hours, minutes, seconds)

        return date

    }

    /**
     * convert dates that come back from API in ISO format, to a UTC date (NOT local time!), without the time part. Just MM/DD/YYYY.
     */
    static convertISODateAsUTC = (datestring: string) => {
      const dateWithoutTime = datestring?.substring(0, datestring?.indexOf('T'))
      const year = dateWithoutTime?.substring(0, 4);
      const month = dateWithoutTime?.substring(5,7);
      const day = dateWithoutTime?.substring(8, dateWithoutTime?.length);

      const formattedDate = `${month}/${day}/${year}`
      return formattedDate;
    }

    static handleStateParam = (stateParam: any) => {
		let formedState = stateParam;
		const isStateParamNaN = isNaN(formedState);
		if (isStateParamNaN && formedState !== undefined) {
		  if (formedState.length === 2) {
			const stateToSaveCode = getStateByAbbrev(formedState);
			stateToSaveCode !== undefined ? formedState = stateToSaveCode : formedState = {};
		  } else {
			const stateToSave = getStateByName(formedState);
			stateToSave !== undefined ? formedState = stateToSave : formedState = {}
			// TODO: Add condition for handling a misspelled State -- using Smartystreets zipCode API
		  }
		}
		return formedState;
	}

    static isValidDate = (dateObject: any) => {
        const from = this.getMinDate();
        const to = this.getMaxDate();

        if(dateObject === null){
            return false
        }
        if (!moment(dateObject).isBetween(from, to)) {
            return false;
        }
        return new Date(dateObject).toString() !== 'Invalid Date';
    }

    static isFutureDate = (date: Date) => {
        if (moment().diff(moment(date)) > 0) return false;
        return new Date(date).toString() !== 'Invalid Date';
    }

    static getDayOfWeekFromItsNumber = (day: number): string => {
        return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][day];
    }

    static trimFormFields = (form: any) => {
        for (let key in form) {
            if (typeof form[key] === "string") {
                const trimmed = form[key].toString().trim()
                form[key] = trimmed;
            }
        }
        return form;
    }

    static deepClone = (data: any) => {
        return JSON.parse(JSON.stringify(data)) || [];
    }

    // Formatting a phone number to be ###-###-#### (no spaces, parentheses, etc)
    static formatPhoneNumber = (unformattedNumber: string) => {
        const justNumbers = unformattedNumber.replace(/\D/g, "");
        // add first dash after you type 4th number
        if (justNumbers.length === 4) {
            const formattedNumber = justNumbers.replace(/(\d{3})(\d{1})/g, "$1-$2");
            return formattedNumber;
            // add second dash after you type 7th number
        } else if (justNumbers.length === 7) {
            const formattedNumber = justNumbers.replace(/(\d{3})(\d{3})(\d{1})/g, "$1-$2-$3");
            return formattedNumber;
            // add second dash on 10th number. Can happen if you copy/paste full number into field
        } if (justNumbers.length === 10) {
            const formattedNumber = justNumbers.replace(/(\d{3})(\d{3})(\d{4})/g, "$1-$2-$3");
            return formattedNumber;
        } else {
            return unformattedNumber;
        }
    }

    static getDocumentDisplayStatus = (status?: string) => {
      let resultDocumentStatus = '';
      if (status) {
        if (status === 'sent') {
          resultDocumentStatus = 'Pending Signature'
        } else if (status === 'signed') {
          resultDocumentStatus = 'Signature Completed'
        } else if (status === 'created') {
          resultDocumentStatus = 'Document Created'
        }
      }
      return resultDocumentStatus;
    }

    // Formatting a social security number (SSN) number to be ###-##-####
    static formatSSN = (unformattedNumber: string) => {
        const justNumbers = unformattedNumber.replace(/\D/g, "");
        if (justNumbers.length === 9) {
            const formattedNumber = justNumbers.replace(/(\d{3})(\d{2})(\d{4})/g, "$1-$2-$3");
            return formattedNumber;
        } else {
            return unformattedNumber;
        }
    }

    // Formatting a social security number (SSN) number to be ###-##-####
    static formatTaxIdNumber = (unformattedNumber: string) => {
        const justNumbers = unformattedNumber.replace(/\D/g, "");
        if (justNumbers.length === 3) {
            const formattedNumber = justNumbers.replace(/(\d{2})(\d{1})/g, "$1-$2");
            return formattedNumber;
        } else if (justNumbers.length === 9) {
            const formattedNumber = justNumbers.replace(/(\d{2})(\d{7})/g, "$1-$2");
            return formattedNumber;
        } else {
            return unformattedNumber;
        }
    }

    static formatTaxIdNumberDisplayed = (unformattedNumber: string) => {
        const justNumbers = unformattedNumber.replace(/\D/g, "");
        if (justNumbers.length === 3) {
            const formattedNumber = justNumbers.replace(/(\d{2})(\d{1})/g, "$1-$2");
            return formattedNumber;
        } else if (justNumbers.length === 9) {
            const formattedNumber = "●●-●●●" + justNumbers.slice(5);
            return formattedNumber;
        } else {
            return unformattedNumber;
        }
    }

    static formatTaxIdNumberDisplayedMixin = (unformattedText: string) => {
        const justTargetCharacters = unformattedText.replace(/[^●\d]/, "");
        if (justTargetCharacters.length < 3) {
            return justTargetCharacters
        }
        return `${justTargetCharacters.slice(0, 2)}-${justTargetCharacters.slice(2)}`
    }

    // Strip non-digit characters from the id
    static unformatIdNumber = (formattedNumber: string) => {
        return formattedNumber.replace(/\D/g, "");
    }

    static setPositiveNegativeNumberTextColor = (hasPositiveNegativeEndTextColor: boolean, number: number) => {
        if (hasPositiveNegativeEndTextColor && number < 0) {
            return "negative-number"
        } else if (hasPositiveNegativeEndTextColor && number > 0) {
            return "positive-number"
        }
    }


    static arrayToString(array:Array<any>, keyValue:any, formatter?: formats ){
        let resultString = "";
        const enumKey = Object.keys(clipboardKeys)[Object.values(clipboardKeys).indexOf(keyValue)];
        array && (array?.length > 0) && array.forEach((e,index)=>{
            let value= e[enumKey];
            if(!value) value = ""
            switch (formatter) {
                case formats.usd:
                    value = formatNumberToUSD(Number(value))
                    break;
                case formats.percentage:
                    if(e.coinsuranceType ==="$"){
                        value = formatNumberToUSD(Number(value))
                    } else if (value) {
                        value +="%"
                    }
                    break;
                default:
                    break;
            }
            resultString += `${keyValue} ${array.length > 1 ? index+1 :""}: ${value}\n`
        })
        return resultString
    }

    static objectToString (object:Clipboard, arrayInfo:Array<string>, optionalText?: string | "", ) {
        let resultString = '';
        resultString+= `${optionalText}\n\n`
        for (const [key, value] of Object.entries(object)) {
            const formattedKey= key.replaceAll("_"," ")
            resultString += `${formattedKey}: ${value}\n`
            if(key === "Patient_Financial_Responsibility") {
                // eslint-disable-next-line no-loop-func
                arrayInfo.forEach(e => {
                    resultString += e
                })
                resultString += "Capacity to Pay Report\n"
            }
        }
        return resultString;
    }

    static getPayorName = (props: { patientId?: number, payorId?: number, selectedPatient: PatientViewModel, selectedEncounter: PatientEncounter}) => {
        const {patientId, payorId, selectedPatient, selectedEncounter} = props;
        if (((patientId === payorId) && (patientId === selectedPatient?.patientId)) || (!payorId)) { // this means that the patient is the name we need
            return `${selectedPatient?.contact?.firstName} ${selectedPatient?.contact?.lastName}`
        } else { // the payor has the name. search through patient champions
            const patientChampions = selectedEncounter?.patientChampion
            let patientName = '';
            if (patientChampions && patientChampions?.length > 0) {
                const champion = patientChampions?.find((patientChampion: PatientChampion) => (
                    patientChampion.patientChampionId === payorId
                ))
                patientName = `${champion?.contact?.firstName} ${champion?.contact?.lastName}`
            }
            return patientName
        }
    }

  static getPayorId = (patientId: number, patientChampions: PatientChampion[]) => {
    let payorId;
    if (patientChampions?.length > 0) {
      patientChampions.forEach((champion: PatientChampion) => {
        payorId = champion?.isGuarantor && champion?.patientChampionId;
      })
    } else {
      payorId = patientId;
    }
    return payorId
  }

    static convertPaymentMethodToReadableInfo = (source: StripePaymentMethod) => {
        if (!(source && (source?.object))) {
            return '';
        }
        let result = "";
        if (source?.object === 'card') {
            result = `${source?.name}'s ${source?.brand} card ending in ${source?.last4}`
        } else if (source?.object?.endsWith('bank_account')) {
            result = `${source?.name}'s bank account ending in ${source?.last4}`
        }
        return result;
    }

    static getInfoForTransaction = (transaction: Payment, paymentMethods?: StripePaymentMethod[]) => {
        if (paymentMethods) {
            return paymentMethods.find((paymentMethod: StripePaymentMethod) => paymentMethod?.id === transaction?.paymentMethodUsed?.externalPaymentMethodId)
        }
        else return {} as StripePaymentMethod
    }

    static windowOpenCentered = (url: string) => {
        const width = window.innerWidth;

        const documentWidth = width / 2;
        const documentHeight = 1000;
        const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;


        const systemZoom = width / window.screen.availWidth;
        const left = (width - documentWidth) / 2 / systemZoom + dualScreenLeft;
        window.open(url, "_blank",
            `
      scrollbars=yes,
      width=${documentWidth / systemZoom},
      height=${documentHeight / systemZoom},
      top=20,
      left=${left}
      `
        )
    }

  static checkIfSelectedPayorIsGuarantor(payorId: number, patientAndChampionList: miniChampion[]) {
    const patientOrChampionToCheck = patientAndChampionList.find((champion: miniChampion ) => (
      champion.id === payorId
    ))
    return !!patientOrChampionToCheck?.isGuarantor
  }

  static getPatientsAndChampionsList(selectedPatient: PatientViewModel, selectedEncounter: PatientEncounter, guarantorChampsOnly: boolean) {
    const patientsAndChampionsList = [];
    patientsAndChampionsList.push({
      id: selectedPatient?.patientId,
      contactId: selectedPatient?.contact?.contactId,
      name: `${selectedPatient?.contact?.firstName} ${selectedPatient?.contact?.lastName}`,
      email: selectedPatient?.contact?.email,
      isGuarantor: true, // patient would be the guarantor if they are selected and 'use for recurring payment' is true
      address: `${selectedPatient?.contact?.primaryAddress?.streetAddress1} ${selectedPatient?.contact?.primaryAddress?.streetAddress2} ${selectedPatient?.contact?.primaryAddress?.city}, ${selectedPatient?.contact?.primaryAddress?.state} ${selectedPatient?.contact?.primaryAddress?.zipCode}`
    })

    selectedEncounter?.patientChampion?.length > 0 && (selectedEncounter?.patientChampion[0] !== emptyPatientChampion)
    && selectedEncounter?.patientChampion.forEach((champion: PatientChampion) => {
      if (guarantorChampsOnly) { // we only want to push champions which are Guarantors
        if (champion?.isGuarantor) {
          patientsAndChampionsList.push({
            id: champion?.patientChampionId,
            contactId: champion?.contact?.contactId,
            name: `${champion?.contact?.firstName} ${champion?.contact?.lastName}`,
            email: champion?.contact.email,
            isGuarantor: champion?.isGuarantor,
            address: `${champion?.contact?.primaryAddress?.streetAddress1} ${champion?.contact?.primaryAddress?.streetAddress2} ${champion?.contact?.primaryAddress?.city}, ${champion?.contact?.primaryAddress?.state} ${champion?.contact?.primaryAddress?.zipCode}`
          })
        }
      } else {
        patientsAndChampionsList.push({
          id: champion?.patientChampionId,
          contactId: champion?.contact?.contactId,
          name: `${champion?.contact?.firstName} ${champion?.contact?.lastName}`,
          email: champion?.contact.email,
          isGuarantor: champion?.isGuarantor,
          address: `${champion?.contact?.primaryAddress?.streetAddress1} ${champion?.contact?.primaryAddress?.streetAddress2} ${champion?.contact?.primaryAddress?.city}, ${champion?.contact?.primaryAddress?.state} ${champion?.contact?.primaryAddress?.zipCode}`
        })
      }
    })
    return patientsAndChampionsList;
  }

  static recentlyViewedAddVerificationClients(recentlyViewed: Array<number>, clientId: number){
    if (!recentlyViewed.includes(clientId)){
      recentlyViewed.unshift(clientId)
    } else {
      recentlyViewed.splice(recentlyViewed.indexOf(clientId),1)
      recentlyViewed.unshift(clientId)
    }
    if(recentlyViewed.length>4){
      recentlyViewed.pop()
    }

        return recentlyViewed
    }

    static getExternalPaymentId = (payment: Payment | any) => { // any type to handle for new structure need to update this for new details structure from transaction details in ledger accordion

        let paymentId = ''; // for paid at facility type which doesn't have an external payment ID

        // or conditional to check if it's a bank (ach) payment and not paid at facility
        if (payment?.paymentMethodUsed?.ach || (payment?.paymentTypeId !== 5 && payment?.paymentMethodTypeId === 2)) {
            paymentId = payment?.paymentMethodUsed?.externalPaymentId || payment?.externalPaymentId || ''
        } else if (payment?.paymentMethodUsed?.cardPayment || (payment?.paymentTypeId !== 5 && payment?.paymentMethodTypeId === 1)) { // for card payment with new details structure and not paid at facility
            paymentId = payment?.paymentMethodUsed?.externalPaymentId || payment?.externalPaymentId || ''
        }
        return paymentId;
    }

    static recentlyViewedAddVerificationPatients(recentlyViewedPatients: Array<recentlyViewedPatientsObj>, recentlyViewedPatient: recentlyViewedPatientsObj){
        const isOnRecentlyViewed = recentlyViewedPatients.some(recentlyViewed => {
            if(
                recentlyViewed.patientId === recentlyViewedPatient.patientId &&
                recentlyViewed.encounterId === recentlyViewedPatient.encounterId
            ){
                return true;
            } else {
                return false;
            }
        })

        if (!isOnRecentlyViewed){
            recentlyViewedPatients.unshift(recentlyViewedPatient)
        } else {
            const index = recentlyViewedPatients.findIndex(recentlyViewed => (
                recentlyViewed.patientId === recentlyViewedPatient.patientId &&
                recentlyViewed.encounterId === recentlyViewedPatient.encounterId
            ))
            recentlyViewedPatients.splice(index,1)
            recentlyViewedPatients.unshift(recentlyViewedPatient)
        }
        if(recentlyViewedPatients.length>4){
            recentlyViewedPatients.pop()
        }

        return recentlyViewedPatients
    }

    static getPaymentTypeName(payment: (Payment | V2PaymentDetail) & { [key: string]: any }, hasDispute?: boolean) {
      let paymentType = hasDispute ? 'Unknown' : 'Unknown - Disputed';
      
      const typeId = payment?.paymentType?.paymentTypeId || payment?.paymentTypeId;
      const methodTypeId = payment?.paymentMethodUsed?.paymentMethodTypeId || payment?.paymentMethodTypeId;
      
      if (typeId === 5 && payment?.paymentAmt === 0) {
        paymentType = ""
      } else if (typeId === 5) {
        hasDispute ? paymentType = "At Facility - Disputed" : paymentType = "At Facility"
      } else if (payment?.paymentMethodUsed || methodTypeId) {
        if (hasDispute) {
          paymentType = (methodTypeId === 1) ? `CC - Disputed` : `ACH - Disputed`;
        } else {
          paymentType = (methodTypeId === 1) ? "CC" : "ACH";
        }
      }
      return paymentType;
    }

    static getTransactionType(payment: Payment | any) { // this needs conditional handling for new ledger accordion payment details
        let paymentType = 'Unknown';
        if ((payment?.paymentType?.paymentTypeId === 5 || payment?.paymentTypeId === 5) && payment?.paymentAmt === 0) {
            paymentType = ""
        } else if (payment?.paymentType?.paymentTypeName) {
            paymentType = payment.paymentType.paymentTypeName
        } else if (payment?.paymentTypeId === 1) {
            paymentType = "Down Payment"
        } else if (payment?.paymentTypeId === 2) {
            paymentType = "Recurring"
        } else if (payment?.paymentTypeId === 3) {
            paymentType = "Specialist"
        } else if (payment?.paymentTypeId === 4) {
            paymentType = "Self"
        } else if (payment?.paymentTypeId === 5 && payment?.paymentAmt !== 0) {
            paymentType = "Facility"
        }
        return paymentType;
    }

    static getTransactionDetails(payment: Payment | any) {
        let transactionDetails = ""
        if ((payment?.paymentMethodUsed?.cardPayment) || (payment?.paymentMethodTypeId === 1)) {
            transactionDetails = `Ending in (${(payment?.last4 || 'N/A')})`
        } else if ((payment?.paymentType?.paymentTypeId === 5 || payment?.paymentTypeId === 5) && (payment?.paymentAmt === 0)) {
            transactionDetails = ""
        } else if ((payment?.paymentType?.paymentTypeId === 5) || (payment?.paymentTypeId === 5)) { // conditional handling for new payment details type in ledger accordion
            transactionDetails = 'Paid At Facility'
        } else {
            transactionDetails = `ACH ●●●●●${(payment?.last4 || '')}`
        }

        return transactionDetails;
    };

    static getStatusColor(statusCard: ClientStatusCardViewModel) {
        const foundStatus = clientFacilityStatus.find((status) => {
            return status.status === statusCard.workflowSubStatus.subStatusName;
        })
        if (foundStatus) {
            return foundStatus.color;
        } else {
            return StatusColors.gray;
        }
    }

    static handleNumericValues = (e: Event) => {
        const input = (e.target as HTMLInputElement).value
        const regEx =/^[0-9]*([.][0-9]*)?$/
        return regEx.test(input);
    }

    // Generates a saml redirect link
    // Can take in no parameters or custom full or partial saml options
    static generateSamlUrl = (options : SamlUrl = {}) : string => {
        const defaults ={
            cognitoDomain : `finpass-${configSettings.env}.auth.us-east-1.amazoncognito.com`,
            responseType : 'code',
            IDP :{
                type : "idp_identifier",
                value : "finpay.net"
            },
            clientID : configSettings.aws_userPoolWebClientId,
            redirectURI : `${configSettings.env !== "prod" ? configSettings.home_uri : "https://admin.finpay.net"}`
        }
        const values = _.merge({}, defaults, options);
        return `https://${values.cognitoDomain}/oauth2/authorize?response_type=${values.responseType}&${values.IDP.type}=${values.IDP.value}&client_id=${values.clientID}&redirect_uri=${values.redirectURI}`;

    }

    // Checks to see if entered email domain matches one in the database
    /**
     * Temporary method, only an example of using idp database
     * information to verify email addresses and redirection
     */
    static includesIDP = (email_domain : string, allProviders : IdProviderFormat[]) : boolean  =>{

        for(const provider of allProviders) {
            if (provider.idp_identifier === email_domain) {
                return true;
            }
        }
        return false;

    }
    // Returns error description for error code
    static getErrorByCode = (code : number) : string =>{
        if(code === 404){
            return "Profile not found";
        }
        else{
            return "";
        }
    }

    static isUserRoleEditableRiskClass = (roleName: string) : boolean => {
        const userRolesEditableRiskClass = ['Finpay Super User', 'Super Admin', 'Finpay Admin', 'Administrator'];
        return userRolesEditableRiskClass.includes(roleName);
    }

    static isUserRoleEditableTypeOfAuthDocs = (roleName: string): boolean => {
        const userRolesEditableTypeOfAuthDocs = ['Finpay Super User', 'Super Admin', 'Finpay Admin', 'Administrator']
        return userRolesEditableTypeOfAuthDocs.includes(roleName)
    }

    static isNullStringValue = (value: any): boolean => {
        if ((value === undefined) ||
            (value === null) ||
            (value.trim() === "")) {
            return true;
        }
        return false;
    }

    static robustErrorHandler = (error: { entity: {code: string, message: string}, errorMessage: string, hasErrors: boolean }, thunkAPI: any) => {
        const { entity, errorMessage } = error;
        if (((entity?.code !== "INTERNAL_SERVER_ERROR") && entity?.message) || (entity?.message)) {
            // handle external error
            thunkAPI.dispatch(showErrorStatus(entity?.message))
            throw new Error(entity?.message)
        } else {
            if (entity) {
                const errorMessage = entity.toString();
                thunkAPI.dispatch(showErrorStatus(errorMessage));
                throw new Error(errorMessage);
            } else {
                // handle internal error
                thunkAPI.dispatch(showErrorStatus(errorMessage))
                throw new Error(errorMessage);
            }
        }
    }

    static customErrorHandler = (errorMessage: string, dispatch: (action: AnyAction) => void, actionCreator: (message: string) => AnyAction) => {
        dispatch(actionCreator(errorMessage));
        throw new Error(errorMessage);
      };



    static buildExternalAccountValueWithFacilityName = (externalAccountIds: any[], facilities: ImplementationFacility[][]) : ExternalAccountItem[] => {

        let retAccountIds:ExternalAccountItem[] = [];
        externalAccountIds.forEach((accountId) => {
            // let extAccountGroup: ImplementationFacility[] = [];
            const sameAccountFacilities = facilities.flat().filter(facility => facility.externalAccount.externalAccountId === accountId);
            if (sameAccountFacilities !== undefined) {
                if (sameAccountFacilities.length > 0) {
                    const facilitiesSortedById = Utils.sortByNumber(sameAccountFacilities, 'facilityId');
                    let newName = accountId + " - " + facilitiesSortedById[0].facilityName;
                    retAccountIds.push({ externalId: accountId, externalIdFacilityName: newName});
                } else {
                    retAccountIds.push({ externalId: accountId, externalIdFacilityName: accountId});
                }
            }

        })

        return retAccountIds;
    }

    static getMinDate = () => moment('1900-01-01')
    static getMaxDate = () => moment('2050-12-31')

    static formatDate = (date: string) => {
        if (!moment(date).isBetween(this.getMinDate(), this.getMaxDate())) {
            const currentYear = moment().format('YYYY');
            const m = moment(date,"YYYY-MM-DD").format("MM");
            const d = moment(date,"YYYY-MM-DD").format("DD")
            return moment(`${currentYear}-${m}-${d}`).format('YYYY-MM-DD');
        }
        return date;
    }

    static dateYearValidate = (e: Event) : string => {
        const val = (e.target as HTMLInputElement).value;
        const y = moment(val,"YYYY-MM-DD").format("YYYY");
        const m = moment(val,"YYYY-MM-DD").format("MM");
        const d = moment(val,"YYYY-MM-DD").format("DD")
        return y + '-' + m + '-' + d;
    }

    static isValidDateTextField = (dateObject: any) => {
        const from = this.getMinDate();
        const to = this.getMaxDate();

        if(dateObject === "" || !moment(dateObject).isBetween(from, to)){
            return false
        }
        return true;
    }

    static getLevelOfCareName = (facilityLevelsOfCare: EstLevelOfCare[], levelOfCareId: number) => {
        const LOCitem = facilityLevelsOfCare.find( (item: EstLevelOfCare) => item.facilityLevelOfCareId === levelOfCareId);
        if (LOCitem !== undefined) {
          return LOCitem.facilityLevelOfCareName;
        }
        return "";
    }

    static getLevelOfCareCode = (facilityLevelsOfCare: EstLevelOfCare[], levelOfCareId: number) => {
        const LOCitem = facilityLevelsOfCare.find( (item: EstLevelOfCare) => item.facilityLevelOfCareId === levelOfCareId);
        if (LOCitem !== undefined) {
          return LOCitem.facilityLevelOfCareCode;
        }
        return "";
    }

    static getErrorText = (formik: FormikProps<any>, name: string, touched?: string[]) => {
        touched = touched === undefined ? [name] : touched ;
        const isError = touched.some(fld => formik.touched[fld]) && formik.errors[name];
        return isError ? formik.errors[name] as string : ""
    }

    static isEmptyValue = (value: any) => {
        value = typeof value === "string" ? value.trim() : value;
        const result = [null, undefined, -1, "-1", ""].some(other => value === other)
        return result
    }

    static isPopulatedValue = (value: any) => {
        return !this.isEmptyValue(value);
    }
}
