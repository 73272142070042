import '../../../../scss/components/_list-header.scss';

import { Button, DeleteModal, LoadingOverlay, Status, Toggle } from '@finpay-development/shared-components';
import GetAppIcon from '@mui/icons-material/GetApp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Divider, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { getClient } from '../../../../implementation-specialist/state/clients/implementation-clients-thunk';
import AccessControl from '../../../../security/components/access-control';
import { RolePageNames } from '../../../../security/model/role-page-names';
import { formatNumberToUSD } from '../../../../shared/calculators';
import { StatusColors } from '../../../../shared/enums';
import { ClientBusinessRules } from '../../../../shared/model/client';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { Utils } from '../../../../shared/utils';
import { patientHelper, patientService } from '../../../services/patient-service';
import { setCalculatedRemainingBalance, setTransactionsCSVData } from '../../../state/patient-slice';
import { releaseCharge, resendPaymentReceipt, resendRefundReceipt, getLedger } from '../../../state/patient-thunk';
import { PatientEncounter } from '../../models/patient-encounter';
import { Payment, PaymentStatusType, V2PaymentDetail } from '../../models/payment';
import { PAYMENT_METHOD_TYPES } from '../../models/payment-method';
import { PaymentStatus } from '../../models/payment-status';
import { PAYMENT_TYPES } from '../../models/payment-type';
import { PFRAdjustment } from '../../models/pfr-adjustment';
import { Transaction } from '../../models/transaction';
import {ClientStatusCardViewModel} from 'src/shared/model/client-status-card';
import { useNavigate } from 'react-router';
import ResendReceiptModal from './resend-receipt-modal';

type menuActions = "refund" | "resend receipt";

interface LedgerAccordionContentsProps {
  selectedEncounter: PatientEncounter;
  isAccountHolder?: boolean,
  ledger?: Ledger[],
  setLedger: (ledger: Ledger[]) => void,
  limit: number
}

export interface Ledger {
  createDt: string;
  ledgerAmt: number;
  ledgerDt: string;
  ledgerTypeId: number;
  ownerUserId: number | null;
  patientEncounterId: number;
  patientLedgerId: number;
}

interface DetailedLedger {
  payment?: V2PaymentDetail;
  dispute?: any;
  reversal?: any;
  createDt: string;
  ledgerAmt: number;
  ledgerDt: string;
  ledgerTypeId: number;
  ownerUserId: number | null;
  patientEncounterId: number;
  patientLedgerId: number;
}


export const LedgerAccordionContents = (props: LedgerAccordionContentsProps) => {
  const { selectedEncounter, isAccountHolder = false, ledger, setLedger, limit } = props;
  const transactions = selectedEncounter?.patientTransactions;
  const [ledgerOffset, setLedgerOffset] = useState(limit)
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [detailedLedger, setDetailedLedger] = useState<DetailedLedger[] | []>([]);

  useEffect(() => { // this useEffect will update the ledgerRecords with the details
    const getNewPaymentDetails = async () => {
      if (!ledger?.length) return;
      
      // Get payments from existing ledger records in state
      const paymentRecords = ledger.filter((record) => 
        [1, 4, 5, 6].includes(record.ledgerTypeId)
      );

      const disputeRecords = ledger.filter((record) => 
        [3].includes(record.ledgerTypeId)
      );

      const reversalRecords = ledger.filter((record) => 
        [2].includes(record.ledgerTypeId)
      );

      // Filter for only new payment records we haven't fetched details for yet
      const newPaymentRecords = paymentRecords.filter(record => 
        !detailedLedger.some(transaction => transaction.patientLedgerId === record.patientLedgerId)
      );

      if (newPaymentRecords.length > 0) { // get details if there are new payment records, repeat for dispute and reversal down the line
        try {
          const newPaymentDetails = await Promise.all(
            newPaymentRecords.map(async (record) => {
              const details = await patientService.getPaymentDetails(record.patientLedgerId) as unknown as V2PaymentDetail;
              return {
                ...record,
                payment: details
              };
            })
          );

          setDetailedLedger(prevPayments => {
            const uniquePayments = [...prevPayments];
            newPaymentDetails.forEach(newPayment => {
              const existingIndex = uniquePayments.findIndex(p => p.patientLedgerId === newPayment.patientLedgerId);
              if (existingIndex >= 0) {
                uniquePayments[existingIndex] = newPayment;
              } else {
                uniquePayments.push(newPayment);
              }
            });
            return uniquePayments;
          });
        } catch (error) {
          console.error('Error fetching payment details:', error);
        }
      }
    };

    getNewPaymentDetails();
  }, [ledger])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const groupedTransactions = transactions ? patientHelper.consolidateTransactions(transactions, isAccountHolder) : []

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // eslint-disable-next-line
  const [transactionId, setTransactionId] = useState(0);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false)
  const [showTransactionDetails, setShowTransactionsDetails] = useState(false)

  const navigate = useNavigate();
  const [optionTexts, setOptionTexts] = useState(
    {
      menuText: "Refund",
      deleteModalTitle: "Refund",
    }
  )
  const resendReceiptButton = {
    menuText: "Resend Receipt",
    refundMenuText: "Resend Refund Receipt",
    modalTitle: "Receipt"
  }
  const formRef: any = useRef();

  const stateFields = {
    isLoading: useSelector((state: RootState) => {
      return state.patientContext.isLoading.patientTransactions;
    }),
    isTransactionsFetchError: useSelector((state: RootState) => {
      return state.patientContext.isError.patientTransactions
    }),
    transactionsCSVData: useSelector((state: RootState) => {
      return state.patientContext.transactionsCSVData
    }),
    currentClientBusinessRule: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.currentClientBusinessRules as ClientBusinessRules
    }),
    missingAddressError: useSelector((state: RootState) => state.patientContext.isError.missingAddress),
    isReleaseChargeError: useSelector((state: RootState) => {
      return state.patientContext.isError.releaseCharge
    }),
    releasePaymentStatusMsg: useSelector((state: RootState) => {
      return state.patientContext.releasePaymentStatusMsg
    }),
    selectedPatient: useSelector((state: RootState) => {
      return state.patientContext.selectedPatient
    }),
    allClients: useSelector((state: RootState) => {
      return state.implementationContext?.implementationSpecialistClient
        ?.allClients
    }),
    selectedPatientEncounter: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter
    }),
  }
  const {isLoading, isTransactionsFetchError, transactionsCSVData, currentClientBusinessRule, missingAddressError, isReleaseChargeError, releasePaymentStatusMsg,selectedPatient, allClients,selectedPatientEncounter} = stateFields;

  const { doPFRAdjustmentsExist, adjustedPFR } = selectedEncounter;
  const patientPaymentProgram = selectedEncounter?.patientPaymentProgram?.[selectedEncounter.patientPaymentProgram.length - 1];
  const pfrAdjustments = selectedEncounter?.patientPfrAdjustments;
  const dispatch = useDispatch<AppDispatch>();

  const getSelectedPayment = () => {
    return selectedEncounter?.patientTransactions?.find((transaction: Transaction) => (
      transaction?.payment?.paymentId === transactionId))?.payment as Payment;
  }

  useEffect(() => {
    if (!isLoading && groupedTransactions?.length > 0) {
      const PFR = (adjustedPFR === 0 || adjustedPFR) ? adjustedPFR : selectedEncounter?.pfrAmt
      const recalculatedBalance = patientHelper.calculateRemainingBalance(groupedTransactions, PFR);
      if (selectedEncounter?.calculatedRemainingBalance !== recalculatedBalance) {
        dispatch(setCalculatedRemainingBalance({calculatedRemainingBalance: recalculatedBalance, isAccountHolder}));
      }
    }
  }, [adjustedPFR, dispatch, groupedTransactions, isAccountHolder, isLoading, selectedEncounter?.calculatedRemainingBalance, selectedEncounter?.pfrAmt])

  useEffect(() => {
    if (currentClientBusinessRule?.clientId !== selectedEncounter?.clientId && !isAccountHolder) {
      dispatch(getClient(selectedEncounter.clientId));
    }
  }, [dispatch, selectedEncounter?.clientId, currentClientBusinessRule?.clientId, isAccountHolder])

  const handleRefreshTransactions = async () => {
    if (selectedEncounter?.patientEncounterId !== 0 && !missingAddressError) {
      const response = await dispatch(getLedger({
        offset: 0,
        limit: 10,
        encounterId: selectedPatientEncounter?.patientEncounterId,
        supressErrors: true,
      }));
      setLedger(response.payload.ledger)
      setLedgerOffset(limit);
    }
  }

  // don't allow refunds to be issued by PES if the client is configured to be the one who issues refunds.
  const disallowRefund = currentClientBusinessRule?.refundsIssuedBy?.toLowerCase() === 'client' && selectedEncounter?.workflow?.workflowId === 3
  function handleReleaseFunds() {
    dispatch(releaseCharge({patientId: selectedEncounter?.patientId, encounterId: selectedEncounter?.patientEncounterId, payment: getSelectedPayment()}))
    setIsRefundModalOpen(false);
  }

  const handleRefundMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    transactionId: number,
    isPaymentTypeCard: boolean,
    isPaymentCaptured?: boolean,
    payment?: V2PaymentDetail,
  ) => {
    const isPaymentPending = payment?.paymentStatus === "Pending"

    if (payment?.paymentTypeId === PAYMENT_TYPES.AT_FACILITY.paymentTypeId) {
      setOptionTexts({menuText: "Remove", deleteModalTitle: "Remove Funds"}) // successful Paid at Facility
    } else if (!isPaymentCaptured || isPaymentPending) {
      if (!isPaymentTypeCard) {
        selectedEncounter?.workflow?.workflowId === 3
          ? setOptionTexts({menuText: "Cancel", deleteModalTitle: "Cancel Transfer"}) // converted cancelling ACH
          : setOptionTexts({menuText: "Remove", deleteModalTitle: "Remove Funds"}) // preconverted cancelling ACH
      } else {
        setOptionTexts({menuText: "Release", deleteModalTitle: "Release Funds"}) // release uncaptured credit card (hold on card)
      }
    } else {
      setOptionTexts({menuText: "Refund", deleteModalTitle: "Issue Refund"}) // successful Card or ACH
    }

    setAnchorEl(event.currentTarget);
    setTransactionId(transactionId); // paymentId
  };

  const handleMenuClose = (menuActionType?: menuActions) => {
    setAnchorEl(null);
    if (menuActionType === 'refund') {
      setIsRefundModalOpen(true);
    } else if (menuActionType === 'resend receipt'){
      setIsReceiptModalOpen(true)
    } 
  };

  const handlePatientLedgerClick = (clientId: number, patientId: number) => {
    window.open(`/specialist/dashboard/client/${clientId}/patient/${patientId}/ledger`, '_blank');
  };

  const handleFailedResponse = (externalResponse: string) => {
      let toolTipTitle: string;
      if (externalResponse.startsWith('{')) {
          const parsedExternalResponse = JSON.parse(externalResponse);
          toolTipTitle = `${parsedExternalResponse.message} - ${parsedExternalResponse.code}:${parsedExternalResponse.declineCode}`;
      } else {
        toolTipTitle = externalResponse;
      }
      return toolTipTitle;
  };

  const getStatusTextReversalItem = (payment: Payment) => {
    const paymentSuccessful = payment?.paymentStatus === 'Success'
    let text = 'Refunded';
    if ((!
      payment?.paymentMethodUsed?.ach) && 
      (!payment?.isCaptured)) 
      { // uncaptured credit card payment
        text = 'Released'
      } else if ((
          payment?.paymentMethodUsed?.ach && (!
          payment?.isCaptured)) || (
          payment?.paymentType?.paymentTypeId === PAYMENT_TYPES.AT_FACILITY.paymentTypeId))
      {
        text = 'Removed'
      }
    if (selectedEncounter?.workflow?.workflowId === 3 && 
      payment?.paymentMethodUsed?.ach && 
      payment?.isCaptured && !paymentSuccessful) {
      text = "Cancelled"
    }
    return text;
  }



  const showStatus = (payment: V2PaymentDetail) => !(payment?.paymentMethodTypeId === 2 && !payment?.isCaptured && !(selectedEncounter?.workflow?.workflowId === 3));


  const getStatusText = (transaction: DetailedLedger, showDetailedTransactions?: boolean) => {
    if (transaction?.payment?.paymentMethodTypeId === 1) {
      if (transaction?.payment?.paymentStatus === "Pending") {
        return transaction?.payment?.isCaptured ? "Pending" : "Uncaptured"
      } else if (transaction?.payment?.paymentStatus === "Failed") {
        return "Failed"
      } else if (transaction?.payment?.paymentStatus === "Success") {
        return transaction?.payment?.isCaptured ? "Success" : "Uncaptured"
      }
    } else if ((transaction?.payment?.paymentTypeId === PAYMENT_TYPES.RECURRING.paymentTypeId) && (!(transaction?.payment?.paymentMethodTypeId === 1) && !(transaction?.payment?.paymentMethodTypeId === 2))) {
      // special condition for if a recurring payment was removed
      return "Failed"
    } else {
      return transaction?.payment?.paymentStatus
    }
    return '';
  }

  const clientItem: ClientStatusCardViewModel = allClients?.find((client:ClientStatusCardViewModel) =>  client.clientId === selectedPatientEncounter.clientId)!

  const transactionSuccess = Array.isArray(transactions)? transactions.map((transaction)=> {
    const paymentStatus = transaction.payment?.paymentStatus !== PaymentStatus.failed;
    const paymentReversalIdExists = transaction.paymentReversal?.paymentReversalId !==null;
    return paymentStatus || paymentReversalIdExists;
  }):[]

  const reversalIdArray = Array.isArray(detailedLedger)
  ? detailedLedger.map((transaction) =>transaction?.reversal?.paymentReversalId).filter(Boolean)
  :[]

  const paymentIdArray = Array.isArray(detailedLedger) ?
  detailedLedger.map((transaction)=> transaction?.payment?.paymentId).filter(Boolean)
  :[]
  
  const reversalPaymentOriginArray = Array.isArray(detailedLedger)?
  detailedLedger.map((transaction)=> transaction?.reversal?.paymentId): [] // might need to review this once move on to reversal details

  // const reversalPaymentOriginArray = Array.isArray(detailedLedger)?
  // detailedLedger.map((transaction)=> transaction?.payment?.paymentReversal?.paymentId): []

  const disputeIdArray = Array.isArray(detailedLedger) ?
  detailedLedger.map((transaction)=> transaction?.dispute?.paymentDisputeId): []

  const handleResendReceipt = (receiptEmail: string) => {

    // Logic to resend the appropriate receipt
    if (paymentIdArray.includes(transactionId)) {
        
        // Dispatch resend payment receipt action
        dispatch(resendPaymentReceipt({ 
            transactionId, 
            receiptEmail 
        }));
    } 
    else if (reversalIdArray.includes(transactionId)) {
       
        // Dispatch resend refund receipt action
        dispatch(resendRefundReceipt({ 
            transactionId, 
            receiptEmail 
        }));
    } 
    else {
        console.error('Transaction ID not found in either array, or payment status is invalid.');
    }
    setIsReceiptModalOpen(false);
  }

  const transactionsHeader = (
    <Grid container spacing={2} className="pl-4 pb-1">
      <Grid container spacing={2} className="header px-1 mb-1">
        <Grid item xs={2}>
          <Typography variant="h4" >
            Initiated Date
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h4">
            Type
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4" className="pl-3">
            Status / Source
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4">
            Amount / ID
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4" >
            Transaction
          </Typography>
          <Typography variant="h4">
            Type
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const pfrAdjustmentsHeader = (
    <Grid container className="header mb-5 pb-2" spacing={2}>
      <Grid item xs={5}/>
      <Grid item xs={3}>
        <Typography variant="h4">
          PFR Adjustments
        </Typography>
      </Grid>
      <Grid item xs={4}/>
    </Grid>
  )

  const pfrAdjustmentListItem = (pfrAdjustment: PFRAdjustment, index: number) => {
    let arrayDate = Utils.convertISODate(new Date(pfrAdjustment.adjustmentDt), showTransactionDetails).split(" ")
    const shortDate = arrayDate[0];
    const fullDate = `${arrayDate[1]} ${arrayDate[2]}`;
    return (
    <Grid container spacing={2} key={index}>
      <Grid item xs={2}>
        <Typography variant="body2">
          {shortDate}
        </Typography>
        <Typography variant="body2">
          {showTransactionDetails && fullDate}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body2">
          PFR
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2" className="pl-3">
          ---
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">
          {formatNumberToUSD(pfrAdjustment?.adjustmentAmt)}
        </Typography>
        <Typography variant="body2">
          {showTransactionDetails && pfrAdjustment?.patientPFRAdjustmentId}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2">
          Adjustment
        </Typography>
      </Grid>
    </Grid>
    )
  };

  const balanceInfo = (
    <Grid
      container
      spacing={2}
      className="px-2"
    >
      <Grid item xs={12} className="mt-6">
        <Divider />
      </Grid>
      <Grid item xs={8} className="">
        <Typography variant="subtitle2">
          Estimated PFR
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end" className="">
          <Typography variant="subtitle2">
            {formatNumberToUSD(selectedEncounter?.pfrAmt)}
          </Typography>
        </Box>
      </Grid>
      {(doPFRAdjustmentsExist) &&
        <>
          <Grid item xs={8} className="">
            <Typography variant="subtitle2">
              Adjusted PFR
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end" className="">
              <Typography variant="subtitle2">
                {formatNumberToUSD(adjustedPFR)}
              </Typography>
            </Box>
          </Grid>
        </>
      }
      <Grid item xs={8} className="">
        <Typography variant="subtitle2" test-id="pending-remaining-balance">
          Pending Remaining Balance
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end" className="">
          <Typography variant="subtitle2">
            {formatNumberToUSD(patientPaymentProgram?.patientPaymentSchedule.pfrPendingBalance)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} className="">
        <Typography variant="subtitle2" test-id="remaining-balance">
          Remaining Balance
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end" className="">
          <Typography variant="subtitle2">
            {formatNumberToUSD(patientPaymentProgram?.patientPaymentSchedule.pfrCapturedBalance)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )

  // this use effect is using hard coded payment data from old endpoint
  useEffect(() => {
    if (!isLoading && detailedLedger && detailedLedger?.length > 0) {
      let fullTable = [];
      let rowValues: string[] = []
      rowValues.push('Date/Time', 'Type', 'Status/Source', 'Amount/Id', "Transaction Type")
      fullTable.push(rowValues)
      detailedLedger.forEach((transaction: DetailedLedger) => { // need to update this for the detailedLedgerType

        let shortDate = "", fullDate = "", shortDateReversal = "", fullDateReversal = ""
        if (transaction?.payment?.paymentInitDt) {
          let arrayDate = Utils.convertISODate(new Date(transaction.payment.paymentInitDt), showTransactionDetails).split(" ")
          shortDate = arrayDate[0]
          if (showTransactionDetails) {
            fullDate = `${arrayDate[1]} ${arrayDate[2]} `
          }
        }
        if (transaction?.reversal?.reversalDate) {
          let arrayDateReversal = Utils.convertISODate(new Date(transaction.reversal.reversalDate), showTransactionDetails).split(" ")
          shortDateReversal = arrayDateReversal[0]
          if (showTransactionDetails) {
            fullDateReversal = `${arrayDateReversal[1]} ${arrayDateReversal[2]} `
          }
        }
        rowValues = [];
        const payment = transaction?.payment;
        const paymentReversal = transaction?.reversal;
        const status = showStatus(payment as V2PaymentDetail) ? (payment?.isCaptured || payment?.paymentMethodTypeId === PAYMENT_METHOD_TYPES.ACH.paymentMethodTypeId) ? payment?.paymentStatus || '' : "Uncaptured" : ""
        const cardInfo = Utils.getTransactionDetails(payment);
        const date = !showTransactionDetails ? `${shortDate}`:`${shortDate} \n ${fullDate}`
        const reversalDate = !showTransactionDetails ? `${shortDateReversal}`:`${shortDateReversal} \n ${fullDateReversal}`
        const amountInfo = !showTransactionDetails
          ? `${formatNumberToUSD(payment?.paymentAmt)}` : `${formatNumberToUSD(payment?.paymentAmt)} \n ${Utils.getExternalPaymentId(payment)}`
        if(transaction?.payment?.paymentId) {
          const paymentInfo = !showTransactionDetails ? `${status}` : `${status} \n ${cardInfo}`
          rowValues.push(
            `${date}`,
            `${payment ? Utils.getPaymentTypeName(payment): ''}`,
            `${(paymentInfo || '')}`,
            `${(amountInfo || '')}`,
            `${(payment ? Utils.getTransactionType(payment) : '')}`
          )
          fullTable.push(rowValues);
        }

        // also a row if the transaction has a reversal/refund
        if (paymentReversal?.paymentReversalId) {
        const paymentInfo = !showTransactionDetails ? `Refund` : `Refund \n ${cardInfo}`
          rowValues = [];
          rowValues.push(
            `${reversalDate}`,
            `${payment ? Utils.getPaymentTypeName(payment): ''}`,
            `${(paymentInfo  || '' )}`,
            `${(amountInfo || '')}`,
            `${(payment ? Utils.getTransactionType(payment) : '')}`
          )
          fullTable.push(rowValues);
        }
      })

      dispatch(setTransactionsCSVData(fullTable));
    }
    // eslint-disable-next-line
  }, [transactions, isLoading, showTransactionDetails])


  const initialValues = {
    showDetailedTransactions: false,
  }

  const validationSchema = Yup.object({
    showDetailedTransactions: Yup.boolean(),
  })

  function checkIfIsValid(value: { showDetailedTransactions: boolean }) {
    setShowTransactionsDetails(value.showDetailedTransactions);
    validationSchema
      .validate(value)
  }

  const disabledDownloadButton = (<Button
      type="text"
      icon={<GetAppIcon />}
      paddingLeft={0}
      disabled
    >
      Download File
    </Button>)
  
  const disabledPatientLedgerButton = (
    <IconButton
      color="primary"
      style={{ fontSize: "1.5rem", float: 'right', paddingRight: '6px', paddingTop: '7px' }}
      disabled
    >
      <OpenInNewIcon fontSize="small" style={{paddingRight: '6px'}} /> View Master Patient Ledger
    </IconButton>
  )

  const handleLoadMore = async () => {
    if (selectedPatientEncounter?.patientEncounterId && ledger && ledger.length % limit === 0) {
      setIsLoadingMore(true);
      try {
        const response = await dispatch(getLedger({
          encounterId: selectedPatientEncounter?.patientEncounterId,
          supressErrors: true,
          offset: ledgerOffset,
          limit: limit
        }));

        // Check if we got any new records back
        if (response.payload?.ledger?.length > 0) {
          setLedger([...ledger, ...response.payload.ledger]);
          setLedgerOffset(prev => prev + limit);
        }
      } catch (error) {
        console.error('Error loading more transactions:', error);
      } finally {
      }
      setIsLoadingMore(false);
    }
  }

  const getMenuButtons = () => {
    const currentTransaction = detailedLedger && detailedLedger.find(
      (transaction) =>
        transaction.payment?.paymentId === transactionId ||
        transaction.reversal?.paymentReversalId === transactionId
    );

    const isTransactionSuccess = currentTransaction?.payment?.paymentStatus === PaymentStatus.success && 
    (currentTransaction?.payment?.isCaptured || currentTransaction?.reversal?.paymentReversalId);

    // Check if this is specifically a refund transaction (not the original transaction)
    const isRefund = reversalIdArray.includes(transactionId);

    //if transaction is disputed, show dispute response option
    if (disputeIdArray.includes(transactionId)) {
      return (
        <MenuItem onClick={() => navigate(`/specialist/dashboard/dispute/${transactionId}`)} className="danger">
          Dispute Response
        </MenuItem>
      );
    } else if (!reversalIdArray.includes(transactionId) && !reversalPaymentOriginArray.includes(transactionId)) {
      // else if transaction is not disputed, show refund and receipt options
      return (
        <>
          {!disallowRefund && (
            <MenuItem onClick={() => handleMenuClose('refund')} className="danger">
              {optionTexts.menuText}
            </MenuItem>
          )}
          {isTransactionSuccess && (
            <MenuItem onClick={() => handleMenuClose('resend receipt')} className="danger">
              {resendReceiptButton.menuText}
            </MenuItem>
          )}
        </>
      );
    } else {
      // else if transaction is refunded, show only receipt option
      return (
        <MenuItem onClick={() => handleMenuClose('resend receipt')} className="danger">
          {isRefund ? resendReceiptButton.refundMenuText : resendReceiptButton.menuText}
        </MenuItem>
      );
    }
  };  

  const paymentListItem = (detailedTransaction: DetailedLedger) => {
    const hasDispute = !!detailedTransaction?.dispute?.paymentId; // TODO: not positive if this is relevant if we're already handling dispute items in a separate component
    const disputeLost = detailedTransaction?.dispute?.disputeStatus === 'lost' // TODO: Not positive on if disputes will be brought in as payments or what exactly
    const isTransactionFailed = detailedTransaction?.payment?.paymentStatus === PaymentStatus.failed;
    let shortDate = "", fullDate = ""
    if(detailedTransaction.ledgerDt) {
      let arrayDate = Utils.convertISODate(new Date(detailedTransaction.ledgerDt), showTransactionDetails).split(" ")
      shortDate = arrayDate[0]
      if(showTransactionDetails)
        fullDate = `${arrayDate[1]} ${arrayDate[2]} `
    }
    const wasPaymentMethodDeleted = (detailedTransaction?.payment?.paymentTypeId === PAYMENT_TYPES.RECURRING.paymentTypeId) && (detailedTransaction?.payment?.paymentMethodTypeId!==1 && detailedTransaction?.payment?.paymentMethodTypeId!==2)

    return (
      <Grid container spacing={2} data-testid="ledger-children">
        <Grid item xs={2}>
          <Typography variant="body2">{shortDate}</Typography>
          <Typography variant="body2" data-testid="payment-full-date">
            {showTransactionDetails && fullDate}
          </Typography>
        </Grid>
        <Grid item xs={1}>
                {!wasPaymentMethodDeleted && (
                    <Typography
                        variant="body2"
                        color={hasDispute ? 'error' : 'textSecondary'}
                    >
                        {Utils.getPaymentTypeName(detailedTransaction.payment as V2PaymentDetail, hasDispute)}
                    </Typography>
                )}
            </Grid>
        <Grid item xs={2}>
          <>
          {showStatus(detailedTransaction?.payment as V2PaymentDetail) && (
            <Typography variant="body2" className="pl-3">
              {(detailedTransaction?.payment?.paymentStatus === 'Failed' && detailedTransaction?.payment?.externalResponse) ? (
                <Tooltip
                  title={handleFailedResponse(detailedTransaction?.payment?.externalResponse)}
                >
                  <div>
                    <Status
                      text={
                        getStatusText(
                          detailedTransaction
                        ) || ''
                      }
                      statusColor={
                        (detailedTransaction?.payment?.paymentStatus === "Success" as PaymentStatusType) &&
                        detailedTransaction?.payment?.isCaptured &&
                        !releasePaymentStatusMsg.errorMsg
                            ? StatusColors.success
                            : (detailedTransaction?.payment?.paymentStatus === "Pending" as PaymentStatusType ||
                                  !detailedTransaction?.payment?.isCaptured) &&
                              !(detailedTransaction?.payment?.paymentStatus === "Failed" as PaymentStatusType)
                            ? StatusColors.warning
                            : StatusColors.error
                    }
                    />
                  </div>
                </Tooltip>
                ) : (
                  <Status
                    text={
                      getStatusText(
                        detailedTransaction
                      ) || ''
                    }
                    statusColor={
                      (detailedTransaction?.payment?.paymentStatus === "Success") &&
                        detailedTransaction?.payment?.isCaptured &&
                        !releasePaymentStatusMsg.errorMsg
                            ? StatusColors.success
                            : (detailedTransaction?.payment?.paymentStatus === "Pending" ||
                                  !detailedTransaction?.payment?.isCaptured) &&
                              !(detailedTransaction?.payment?.paymentStatus === "Failed")
                            ? StatusColors.warning
                            : StatusColors.error
                    }
                  />
              )}
            </Typography>
          )}
          {hasDispute && showTransactionDetails && !disputeLost && (
            <>
                <Typography variant="body2" color="error">
                  {/* TODO: Follow up with Haider about if this is relevant in the payments accordion, not sure if and how payments are linked to disputes */}
                    {'Dispute status: ' +
                        detailedTransaction?.dispute?.disputeStatus}
                </Typography>
                <Typography variant="body2" color="error">
                    {'Reason: ' + detailedTransaction?.dispute?.disputeReason}
                </Typography>
            </>
        )}
        {showTransactionDetails && wasPaymentMethodDeleted && (
            <Typography variant="body2" color="error">
                No Recurring Payment Method in Stripe
            </Typography>
        )}
            </>
            <Typography
                variant="body2"
                className="pl-2"
                color={
                    hasDispute && !disputeLost ? 'error' : 'textSecondary'
                }
            >
        {showTransactionDetails && !wasPaymentMethodDeleted
            ? Utils.getTransactionDetails(detailedTransaction?.payment)
            : ''}
    </Typography>
          
        </Grid>
        <Grid item xs={4}>
                <Typography variant="body2">
                    {formatNumberToUSD(detailedTransaction?.ledgerAmt)}
                </Typography>
                <Typography variant="body2" className="break-word">
                    {showTransactionDetails &&
                        Utils.getExternalPaymentId(detailedTransaction?.payment)}
                </Typography>
                <Typography variant="body2" className="break-word">
                    {showTransactionDetails &&
                        hasDispute &&
                        !disputeLost &&
                        detailedTransaction?.dispute?.externalDisputeId}
                </Typography>
            </Grid>
            <Grid item xs={isAccountHolder ? 3 : 2}>
                <Typography variant="body2">
                    {Utils.getTransactionType(detailedTransaction?.payment)}
                </Typography>
            </Grid>
        {ledger && 
        !isTransactionFailed &&
        !isAccountHolder &&
        transactionSuccess &&
        !disallowRefund ? (
          <Grid item xs={1} style={{marginTop: -12, maxHeight: 10}}>
            <IconButton
            aria-haspopup='true'
            onClick={e => {
              const isPaymentTypeCard =
                detailedTransaction?.payment?.paymentMethodTypeId ===
                PAYMENT_METHOD_TYPES.CARD
                  .paymentMethodTypeId;
              const isPaymentCaptured = detailedTransaction?.payment?.isCaptured;
              handleRefundMenuClick(
                e,
                detailedTransaction?.payment?.paymentId as number,
                isPaymentTypeCard,
                isPaymentCaptured,
                detailedTransaction?.payment
              );
              {
                detailedTransaction?.dispute ? setTransactionId(detailedTransaction?.dispute.paymentDisputeId)
                  : detailedTransaction?.payment ? setTransactionId(detailedTransaction?.payment?.paymentId)
                    : setTransactionId(0)
              }
            }}
            size='large'
            >
              <MoreHorizIcon/>
            </IconButton>
          </Grid>
          ) :
          !detailedTransaction?.reversal?.paymentId && // not sure if this will be relevant for the payment, look at how old version is handled in the transaction accordion, might need to adjust backend payment details logic to include this
          !isAccountHolder &&
          !isTransactionFailed &&
          detailedTransaction?.payment?.paymentStatus !== PaymentStatus.failed &&
          !disallowRefund &&
          !hasDispute &&(
            <Grid item xs={1} style={{marginTop: -12, maxHeight: 10}}>
                   <IconButton
                        aria-haspopup="true"
                        onClick={e => {
                            const isPaymentTypeCard =
                                detailedTransaction?.payment?.paymentMethodTypeId ===
                                PAYMENT_METHOD_TYPES.CARD
                                    .paymentMethodTypeId;
                            const isPaymentCaptured =
                                detailedTransaction?.payment?.isCaptured;
                            handleRefundMenuClick(
                                e,
                                detailedTransaction?.payment?.paymentId as number,
                                isPaymentTypeCard,
                                isPaymentCaptured,
                                detailedTransaction?.payment
                            ); 
                        }}
                        size="large"
                    >
                        <MoreHorizIcon/>
                    </IconButton>
            </Grid>
        )}
    </Grid>
    );

  }

  // const paymentDisputeListItem = (payment: Payment, paymentDispute: PaymentDispute) => {
  //   let shortDate = "", fullDate = ""
  //   if(paymentDispute.disputeCloseDt) {
  //     let arrayDate = Utils.convertISODate(new Date(paymentDispute.disputeCloseDt), showTransactionDetails).split(" ")
  //     shortDate = arrayDate[0]
  //     if(showTransactionDetails)
  //     fullDate = `${arrayDate[1]} ${arrayDate[2]} `
  //   }

  //   return (
  //     <Grid container spacing={2}>
  //       <Grid item xs={2}>
  //         <Typography variant="body2">
  //           {shortDate}
  //         </Typography>
  //         <Typography variant="body2">
  //           {showTransactionDetails && fullDate}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={1}>
  //           <Typography variant="body2">
  //             Dispute
  //           </Typography>
  //         </Grid>
  //       <Grid item xs={2}>
  //         <>
  //           {(showStatus(payment)) && (
  //             <Typography variant="body2" className="pl-3">
  //               <Status
  //                 text={isAccountHolder ? 'Dispute Won' : 'Dispute Lost'}
  //                 statusColor={
  //                   isAccountHolder ? StatusColors.success : StatusColors.error
  //                 }
  //               />
  //             </Typography>
  //           )}
  //           {showTransactionDetails && (
  //             <Typography variant="body2">
  //               {'Reason: ' + paymentDispute?.disputeReason}
  //             </Typography>
  //           )}
  //         </>
  //         <Typography variant="body2" className="pl-2">
  //           {showTransactionDetails ?
  //             Utils.getTransactionDetails(payment) : ""
  //           }
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={4}>
  //         <Typography variant="body2" color="error">
  //           {formatNumberToUSD(paymentDispute?.disputeAmt)}
  //         </Typography>
  //         <Typography variant="body2" className="break-word">
  //           {showTransactionDetails && (
  //             paymentDispute?.externalDisputeId
  //           )}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={isAccountHolder ? 3:2}>
  //         <Typography variant="body2">
  //           {Utils.getTransactionType(payment)}
  //         </Typography>
  //       </Grid>
  //     </Grid>
  //   )
  // }

  // const reversedTransactionsListItem = (payment: Payment, paymentReversal: PaymentReversal) => {
  //   let shortDate = "", fullDate = ""
  //   if(paymentReversal?.reversalDate) {
  //     let arrayDate = Utils.convertISODate(new Date(paymentReversal.reversalDate), showTransactionDetails).split(" ")
  //     shortDate = arrayDate[0]
  //     if(showTransactionDetails)
  //     fullDate = `${arrayDate[1]} ${arrayDate[2]} `
  //   }
  //   // "To do: Remove `reservalAmount` and use `reservalAmt` after rebuilding the transaction Java API in Node.js."
  //   const amount = (paymentReversal?.reversalAmount ?? paymentReversal?.reversalAmt) ?? 0;
  //   return (
  //     <Grid container spacing={2}>
  //       <Grid item xs={2}>
  //         <Typography variant="body2">
  //           {shortDate}
  //         </Typography>
  //         <Typography variant="body2">
  //           {showTransactionDetails && fullDate }
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={1} >
  //           <Typography variant="body2" color="textSecondary">
  //             {Utils.getPaymentTypeName(payment)}
  //           </Typography>
  //         </Grid>
  //       <Grid item xs={2}>
  //         <Typography variant="body2" className="pl-2">
  //             <Status
  //               text={getStatusTextReversalItem(payment)}
  //               statusColor={
  //                 payment?.paymentStatus === PaymentStatus.success ? StatusColors.success :
  //                 (payment?.paymentStatus === PaymentStatus.pending ? StatusColors.warning
  //                 : StatusColors.error)
  //               }
  //             />
  //         </Typography>
  //       <Typography variant="body2" className="pl-2">
  //           {showTransactionDetails ?
  //             Utils.getTransactionDetails(payment) : ""
  //           }
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={4} style={{maxHeight: 10}}>
  //         <Typography variant="body2" color="error">
  //           {formatNumberToUSD(amount && -Math.abs(amount))}
  //         </Typography>
  //         <Typography variant="body2" className="break-word">
  //           {showTransactionDetails && (
  //             paymentReversal?.externalReversalId
  //           )}
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={2}>
  //         <Typography variant="body2">
  //           {Utils.getTransactionType(payment)}
  //         </Typography>
  //       </Grid>
  //       {((paymentReversal?.paymentId) && (!isAccountHolder)) && (
  //         <Grid item xs={1} style={{marginTop: -12, maxHeight: 10}}>
  //           <>
  //             <IconButton
  //               aria-haspopup="true"
  //               onClick={(e) => {
  //                 const isPaymentTypeCard = (payment?.paymentMethodUsed?.paymentMethodTypeId === PAYMENT_METHOD_TYPES.CARD.paymentMethodTypeId)
  //                 const isPaymentCaptured = payment?.isCaptured
  //                 handleRefundMenuClick(e, payment?.paymentId, isPaymentTypeCard, isPaymentCaptured, payment)
  //                 setTransactionId(paymentReversal?.paymentReversalId)
  //               }}
  //               size="large">
  //               <MoreHorizIcon />
  //             </IconButton>
  //           </>
  //         </Grid>
  //       )}
  //     </Grid>
  //   );
  // }

  return <>
    <div className="pb-7 px-1">
      <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Send Ledger" isAccountHolderPortal={true}
        renderNoAccess={() => (disabledDownloadButton)}
      >
        {!isLoading && !isTransactionsFetchError && ledger!==undefined && ledger?.length > 0 ? (
          <CSVLink data={transactionsCSVData} filename={'Transactions_Data.csv'}>
          <Button
            type="text"
            icon={<GetAppIcon />}
            paddingLeft={0}
          >
            Download File
          </Button>
        </ CSVLink>
        ) : (disabledDownloadButton)
      }
      </AccessControl>
      <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Patient Ledger" isAccountHolderPortal={true}
        renderNoAccess={() => (disabledPatientLedgerButton)}
      >
        <IconButton
            color="primary"
            style={{ fontSize: "1.5rem", float: 'right', paddingRight: '6px', paddingTop: '7px' }}
            onClick={() =>
              handlePatientLedgerClick(selectedEncounter?.clientId, selectedEncounter?.patientId)
            }
          >
            <OpenInNewIcon fontSize="small" style={{paddingRight: '6px'}} /> View Master Patient Ledger
          </IconButton>
      </AccessControl>
      {!isAccountHolder && (
        <Grid container className="my-3 ml-2">
          <Grid xs={12} item>
            <Typography variant="h3" className="mb-2">
              Details
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validate={checkIfIsValid}
              onSubmit={() => {}}
            >
              {(formik) => (
                <Form>
                  <div className="mb-6">
                    <Toggle
                      name="showDetailedTransactions"
                      formik={formik}
                      value={formik.values.showDetailedTransactions}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      )}
    </div>
    <div className="list-header documents-accordion px-4 pb-4">
      {isTransactionsFetchError ? (
        <Grid container direction="row">
          <Grid item xs={10}>
            <Typography variant="h4">
              Transactions Pending. Use Refresh button to update.
            </ Typography>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end" marginTop="-1.2rem" marginRight="-1.5rem">
              <IconButton onClick={() => handleRefreshTransactions()} size="large">
                <RefreshIcon className="icon" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          {isLoading ? (
            <LoadingOverlay whiteBackground />
            ) : (
              <div className="px-1">
                {transactionsHeader}
                <div className="striped-row-container" data-testid="ledger-list">
                  {detailedLedger && detailedLedger.length > 0 ? (
                    detailedLedger.map((ledgerItem: DetailedLedger) => (

                        <React.Fragment key={ledgerItem.patientLedgerId}>
                          {/* {ledgerListItem(ledgerItem)} OLD VERSION HERE*/}
                          {ledgerItem?.payment &&
                            paymentListItem(ledgerItem)
                          }
                          {/* {ledgerItem?.paymentReversal && // need to update here for reversals
                            reversedTransactionsListItem(ledgerItem?.payment, ledgerItem?.paymentReversal)
                          }
                          {ledgerItem?.paymentDispute?.paymentDisputeId && (ledgerItem?.paymentDispute?.disputeStatus === 'lost') && // need to update here for disputes
                            paymentDisputeListItem(ledgerItem?.payment, ledgerItem?.paymentDispute)
                          } */}
                        </React.Fragment>

                    ))
                  ) : (
                    <Typography className="mt-4" variant="h4">
                      There are no transactions available
                    </Typography>
                  )}
                </div>
                  {ledger && ledger?.length % limit === 0 && ledger.length > 0 && (
                    <Box 
                    display="flex" 
                    justifyContent="center" 
                    width="100%" 
                    >
                      {isLoadingMore ? (
                        <LoadingOverlay whiteBackground />
                      ) : (
                        <IconButton
                          color="primary"
                          title="Open Details"
                          style={{
                            fontSize: '1.5rem',
                          }}
                          onClick={handleLoadMore}
                        >
                          <AddCircleOutlineIcon fontSize="medium" style={{marginRight: '0.5rem'}}/>
                          Load More Transactions
                        </IconButton>
                      )}
                    </Box>
                  
                  )}
                {doPFRAdjustmentsExist && (
                    <React.Fragment>
                      {pfrAdjustmentsHeader}
                      <div className="striped-row-container">
                        {pfrAdjustments && pfrAdjustments.map((pfrAdjustment: PFRAdjustment, index: number) => pfrAdjustmentListItem(pfrAdjustment, index))}
                      </div>
                    </React.Fragment>
                  )}
              </div>
            )}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => handleMenuClose()}
            >
              <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Add Instance of Care"
                renderNoAccess={() => <MenuItem disabled>danger</MenuItem>}>
                {getMenuButtons()}
                <></>
              </AccessControl>
            </Menu>
        </>
      )}
    </div>
    {balanceInfo}
    {isReceiptModalOpen && (
      <ResendReceiptModal
        open={isReceiptModalOpen}
        receiptEmail={selectedPatient?.contact.email}
        titleText={reversalIdArray.includes(transactionId) ? resendReceiptButton.refundMenuText + '?' : resendReceiptButton.menuText + '?'}
        onSend={handleResendReceipt}
        onClose={()=> setIsReceiptModalOpen(false)}
      />
    )}
    {isRefundModalOpen && (
      <DeleteModal
        open={isRefundModalOpen}
        title={optionTexts.deleteModalTitle}
        subTitle="This action cannot be undone"
        okButtonText= {`${optionTexts.deleteModalTitle}`}
        handleDeleteCancel={() => setIsRefundModalOpen(false)}
        handleDeleteOk={handleReleaseFunds}
      />
    )}
  </>;
}